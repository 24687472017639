import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { isDate } from 'validator';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import SoraTextField from 'components/Input/TextArea';
import YearMonthDatePicker from 'components/DatePicker/YearMonthDatePicker';
import { TEXT } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	EDUCATION_LEVEL_OPTIONS,
	EMPLOYMENT_STATUS_OPTIONS,
	REFINANCE_OFFER_TYPE,
	STUDENT
} from 'shared/constants';

function StudentLoanRefinanceForm({
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	assetsAmount,
	cityValue,
	clientId,
	creditScore,
	disableAutoComplete,
	dobValue,
	educationLevelValue,
	emailAddressValue,
	employerValue,
	employmentStartDate,
	employmentStatusValue,
	firstName,
	graduationYearValue,
	lastName,
	lenderType,
	loanRequestId,
	monthlyHousingPaymentValue,
	outstandingBalance,
	postSuccessEvent,
	rentOrOwnValue,
	schoolValue,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;
	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	const [tradelineId, setTradelineId] = useState('');

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	// Pre-populated state items
	const [fullName, setFullName] = useState(fullNameValue || '');
	const [employer, setEmployer] = useState(employerValue || '');
	const [employmentStatus, setEmploymentStatus] = useState(
		employmentStatusValue || ''
	);
	const [educationLevel, setEducationLevel] = useState(
		educationLevelValue || ''
	);
	const [startDate, setStartDate] = useState(employmentStartDate || '');
	const [school, setSchool] = useState(schoolValue || '');
	const [graduationDate, setGraduationDate] = useState(
		graduationYearValue || ''
	);
	const [dob, setDob] = useState(dobValue || '');
	const [currentLoanType, setCurrentLoanType] = useState(lenderType || '');
	const [currentOutstandingBalance, setCurrentOutstandingBalance] = useState(
		outstandingBalance || ''
	);
	const [currentInterestRate, setCurrentInterestRate] = useState();
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [totalAssetsValue, setTotalAssetsValue] = useState(assetsAmount || 0);
	const [rentOrOwn, setRentOrOwn] = useState(rentOrOwnValue || '');
	const [monthlyPayment, setMonthlyPayment] = useState(
		monthlyHousingPaymentValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('Student Loan Refinance Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [fullName];
	const isFormReady = useMemo(() => isSubmissionReady(formData), formData);

	useEffect(() => {
		if (outstandingBalance) {
			setCurrentOutstandingBalance(outstandingBalance.toString());
		}
		if (lenderType) {
			setCurrentLoanType(lenderType.toLowerCase());
		}
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
	}, [outstandingBalance, lenderType, refinancingTradelineId]);

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const GRADUATION_YEAR_ONLY_VIEW = ['year']; // This is for the DatePicker of Graduation Year

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has a Student Loan Refinance Request for one of their clients.
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a Student Loan Refinance Request for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's Date of birth: ${dob}

			Client's Employer: ${employer}
			Client's Employment Status: ${employmentStatus}
			Client's Employment Start year: ${startDate}
			Client's Total Annual Income: ${totalAnnualIncome}
			Client's Assets Value: ${totalAssetsValue}

			Client's Education Level: ${educationLevel}
			School Client Attended: ${school}
			Client's Graduation Year: ${graduationDate}

			Does Client rent or own? ${rentOrOwn}
			Client's monthly housing cost: ${monthlyPayment}
			Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}

			Current Loan Details:
			Current Student Loan is: ${currentLoanType}
			Outstanding Balance of Current Loan: ${currentOutstandingBalance}
			Interest Rate of Current Loan: ${currentInterestRate}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			...formData,
			emailAddress,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			dob,
			employer,
			employmentStatus,
			startDate,
			totalAnnualIncome,
			totalAssetsValue,
			educationLevel,
			school,
			graduationDate,
			currentLoanType,
			currentInterestRate,
			currentOutstandingBalance,
			additionalNotes,
			rentOrOwn,
			monthlyPayment
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setEmployer,
						setEmploymentStatus,
						setEducationLevel,
						setStartDate,
						setSchool,
						setGraduationDate,
						setDob,
						setCurrentLoanType,
						setCurrentInterestRate,
						setCurrentOutstandingBalance,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setAdditionalNotes,
						setMonthlyPayment,
						setRentOrOwn,
						setSubject
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentOutstandingBalance,
			currentLoanType,
			currentInterestRate,
			dob,
			educationLevel,
			emailAddress,
			employer,
			employmentStatus,
			firstName,
			graduationDate,
			lastName,
			monthlyPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			school,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: STUDENT,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentOutstandingBalance,
			currentLoanType,
			currentInterestRate,
			dob,
			educationLevel,
			emailAddress,
			employer,
			employmentStatus,
			firstName,
			graduationDate,
			lastName,
			monthlyPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			school,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: STUDENT,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setEmployer,
						setEmploymentStatus,
						setEducationLevel,
						setStartDate,
						setSchool,
						setGraduationDate,
						setDob,
						setCurrentLoanType,
						setCurrentOutstandingBalance,
						setCurrentInterestRate,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setAdditionalNotes,
						setMonthlyPayment,
						setRentOrOwn,
						setSubject
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: totalAssetsValue,
				label: 'Total assets value:'
			},
			{
				type: 'currency',
				value: monthlyPayment,
				label: 'Monthly payment:'
			},
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: currentOutstandingBalance,
				label: 'Outstanding balance:'
			},
			{
				type: 'rate',
				value: currentInterestRate,
				label: 'Current interest rate:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				Client's name: ${fullName}
				Client's dob: ${dob}
				${formattedCollection}
				Employment Status: ${employmentStatus}
				Employer Name: ${employer}
				Employment Start Date: ${startDate}
				Highest degree obtained: ${educationLevel}
				School attended: ${school}
				Graduation year: ${graduationDate}
				Rent or own: ${rentOrOwn}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				Current loan type: ${currentLoanType}

				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: '',
				dealname: `${advisorName} / ${fullName}`,
				dealtype: loanType,
				borrower: fullName,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Student Loan Refinance
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						{sendFormDataError && <Alert variant="error" />}

						{/* Modal shown on successful submission of  */}
						<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

						{/* Full Name */}
						<Input
							id="fullName"
							label="Full Name"
							type={TEXT}
							name="Full name"
							onChange={setFullName}
							value={fullName}
							endAdornment="hidden"
							sx={{
								width: '100%'
							}}
							inputProps={{
								tabIndex: '1',
								'data-test': 'fullName'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Date of Birth */}
						<StandardDatePicker
							id="dob"
							label="Date of birth"
							helperText="Enter your birth date"
							onChange={setDob}
							value={dob}
							error={dob === null || !isValidDate}
							errorText="Date of birth needs to be a valid date"
							inputProps={{
								tabIndex: '2',
								'data-test': 'dateOfBirth'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label="Total annual income"
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								tabIndex: '3',
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Assets Value */}
						<CurrencyTextInput
							label="Total assets value"
							value={totalAssetsValue}
							onChange={setTotalAssetsValue}
							inputProps={{
								tabIndex: '4',
								'data-test': 'totalAssetsValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="5"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employer */}
						<Input
							label="Employer name"
							value={employer}
							onChange={setEmployer}
							type={TEXT}
							inputProps={{
								tabIndex: '6',
								'data-test': 'employer'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Start date */}
						<StandardDatePicker
							id="startDate"
							label="Employment start date"
							onChange={setStartDate}
							value={startDate}
							error={startDate === null || !isValidDate}
							errorText="Start date needs to be a valid date"
							inputProps={{
								tabIndex: '7',
								'data-test': 'startDate'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Education Level */}
						<Dropdown
							items={EDUCATION_LEVEL_OPTIONS}
							selected={educationLevel}
							onChange={setEducationLevel}
							variant="outlined"
							label="Highest degree obtained"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="8"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* School Attended */}
						<Input
							inputProps={{
								'data-test': 'school',
								tabIndex: '9'
							}}
							type={TEXT}
							label="School attended"
							value={school}
							onChange={setSchool}
							subLabel="Name of school"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Graduation Month and Year */}
						<YearMonthDatePicker
							helperText="Enter a year like 2022"
							inputFormat="yyyy"
							label="Graduation month and year"
							onChange={setGraduationDate}
							variant="outlined"
							value={graduationDate}
							views={GRADUATION_YEAR_ONLY_VIEW}
							inputProps={{
								tabIndex: '10',
								'data-test': 'monthAndYearOfGraduation'
							}}
							sx={{ width: '100%' }}
							gridColumns
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Rent or own */}
						<Dropdown
							items={['Rent', 'Own']}
							selected={rentOrOwn}
							onChange={setRentOrOwn}
							variant="outlined"
							label="Do you rent or own your primary residences?"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="11"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Monthly Payment Amount */}
						<CurrencyTextInput
							label="Monthly payment"
							value={monthlyPayment}
							onChange={setMonthlyPayment}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="12"
						/>
					</Grid>

					{/* Current Address */}
					<GetFullAddressPartialForm
						label="Current address"
						addressLine1={addressLine1}
						addressLine1TabIndex="13"
						addressLine2={addressLine2}
						addressLine2TabIndex="14"
						city={city}
						cityTabIndex="15"
						state={state}
						stateTabIndex="16"
						zipCode={zipCode}
						zipCodeTabIndex="17"
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<Typography
							variant="h3Gascogne"
							gutterBottom
							component="div"
							sx={{
								marginTop: 6
							}}
						>
							Current loan details
						</Typography>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Type */}
						<Dropdown
							dataTestTag="loanType"
							items={['federal', 'private']}
							selected={currentLoanType}
							onChange={setCurrentLoanType}
							variant="outlined"
							label="Lender type"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="18"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Balance */}
						<OutstandingBalanceInput
							label="Outstanding balance of current loan"
							outstandingBalance={currentOutstandingBalance}
							setOutstandingBalance={setCurrentOutstandingBalance}
							inputProps={{
								tabIndex: '19',
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current interest rate *"
							inputProps={{
								tabIndex: '20',
								'data-test': 'currentInterestRate'
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={21}
						/>
					</Grid>
				</Grid>
			)}
			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<PrimaryButton
					disabled={!isFormReady}
					onClick={submitForm}
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={21}
				>
					Submit
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default StudentLoanRefinanceForm;
