import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert/index';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/TextArea';
import { NUMBER } from 'components/Input/Types';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import TextInput from 'components/Input/TextInput';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Utils
import {
	clearFormValues,
	isSubmissionReady,
	dollarFormatter
} from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	TYPES_OF_HOME_PROPERTIES,
	MORTGAGE,
	NEW_LOAN_OFFER_TYPE,
	MORTGAGE_LOAN_TENURE_OPTIONS
} from 'shared/constants';

function NewMortgageForm({
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	downPaymentPercentValue,
	emailAddressValue,
	estimatedPurchaseAmount,
	firstName,
	firstTimeHomeBuyerValue,
	disableAutoComplete,
	setDisableAutoComplete,
	lastName,
	loanAmountValue,
	loanRequestId,
	ownershipTenureValue,
	postSuccessEvent,
	primaryUseValue,
	propertyTypeValue,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [fullName, setFullName] = useState(fullNameValue || '');
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);

	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedPurchaseAmount || ''
	);
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [downPaymentPercent, setDownPaymentPercent] = useState(
		downPaymentPercentValue || ''
	);
	const [firstTimeHomeBuyer, setFirstTimeHomeBuyer] = useState(
		firstTimeHomeBuyerValue || 'Yes'
	);
	const [ownershipTenure, setOwnershipTenure] = useState(
		ownershipTenureValue || ''
	);

	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('New Mortgage Loan Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState('');
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	// Address Related
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const formData = [
		estimatedPurchaseValue,
		loanAmount,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const loanRequestDetailsRequiredFields = [
		propertyType,
		primaryUse,
		annualIncome,
		downPaymentPercent,
		estimatedPurchaseValue,
		loanAmount,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			),
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has requested a New Mortgage Loan for one of their clients. 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for New Mortgage Loan for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's credit score: ${creditScore}
			Client's total annual income: ${dollarFormatter.format(totalAnnualIncome)}
			Client's estimated purchase value: ${dollarFormatter.format(
				estimatedPurchaseValue
			)}
			Client's primary use for property: ${primaryUse}
			Client's entered property type: ${propertyType}
			Client's desired loan amount: ${loanAmount}
			Client's down payment %: ${downPaymentPercent}
			First time home buyer?: ${firstTimeHomeBuyer}
			Client's expected ownership tenure: ${ownershipTenure}

			Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			ownershipTenure,
			additionalNotes,
			advisorName,
			advisorEmail,
			fullName,
			emailAddress,
			creditScore,
			totalAnnualIncome,
			estimatedPurchaseValue,
			primaryUse,
			propertyType,
			loanAmount,
			downPaymentPercent,
			firstTimeHomeBuyer,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setCreditScore,
						setLoanAmount,
						setTotalAnnualIncome,
						setAddressLine1,
						setAddressLine2,
						setPrimaryUse,
						setEstimatedPurchaseValue,
						setDownPaymentPercent,
						setOwnershipTenure,
						setPropertyType,
						setFirstTimeHomeBuyer,
						setAdditionalNotes,
						setCity,
						setState,
						setZipCode,
						setEmailAddress,
						setSubject
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			downPaymentPercent,
			estimatedPurchaseValue,
			firstName,
			firstTimeHomeBuyer,
			lastName,
			loanAmount,
			offerType: NEW_LOAN_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			downPaymentPercent,
			estimatedPurchaseValue,
			firstName,
			firstTimeHomeBuyer,
			lastName,
			loanAmount,
			offerType: NEW_LOAN_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCreditScore,
						setDownPaymentPercent,
						setEmailAddress,
						setEstimatedPurchaseValue,
						setFirstTimeHomeBuyer,
						setFullName,
						setLoanAmount,
						setOwnershipTenure,
						setPrimaryUse,
						setPropertyType,
						setState,
						setSubject,
						setTotalAnnualIncome,
						setZipCode
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: estimatedPurchaseValue,
				label: 'Estimated Purchase Value:'
			},
			{
				type: 'percent',
				value: downPaymentPercent,
				label: 'Down Payment:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired Loan Amount:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				First Time Home Buyer: ${firstTimeHomeBuyer}
				${formattedCollection}
				Property Type: ${propertyType}
				Primary Use: ${primaryUse}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullName}`,
				dealtype: loanType,
				borrower: fullName,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						New Mortgage Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Type of property input */}
						<Dropdown
							items={[
								'Single family home',
								'Apartment',
								'Townhouse',
								'Condominium',
								'Mobile home',
								'Multi-family home',
								'Bungalow',
								'Farmhouse'
							]}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label={
								atLoanRequestDetailsUrl
									? 'Property type *'
									: 'Property type'
							}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="1"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Primary use of property */}
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={primaryUse}
							onChange={setPrimaryUse}
							variant="outlined"
							label={
								atLoanRequestDetailsUrl
									? 'Property use *'
									: 'Property use'
							}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="2"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* First time home owner */}
						<Dropdown
							items={['Yes', 'No']}
							selected={firstTimeHomeBuyer}
							onChange={setFirstTimeHomeBuyer}
							variant="outlined"
							label="First time home buyer?"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="3"
							sx={{ marginTop: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Annual income *'
									: 'Annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							type={NUMBER}
							inputProps={{
								tabIndex: '4',
								'data-test': 'totalAnnualIncome'
							}}
							sx={{ marginTop: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Purchase Value */}
						<CurrencyTextInput
							label="Estimated Purchase Value *"
							subLabel="Estimated price of home"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
							inputProps={{
								tabIndex: '5',
								'data-test': 'estimatedPurchaseValue'
							}}
							sx={{ marginTop: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Down payment % */}
						<TextInput
							type="percent"
							label={
								atLoanRequestDetailsUrl
									? 'Down payment % *'
									: 'Down payment %'
							}
							helperText="Down payment cannot be empty"
							onChange={setDownPaymentPercent}
							value={downPaymentPercent}
							inputProps={{
								tabIndex: '6',
								'data-test': 'downPaymentPercent'
							}}
							sx={{ marginTop: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Desired Loan Amount */}
						<CurrencyTextInput
							label="Desired loan amount *"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								tabIndex: '7',
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<Dropdown
							items={MORTGAGE_LOAN_TENURE_OPTIONS}
							selected={ownershipTenure}
							onChange={setOwnershipTenure}
							variant="outlined"
							label="Desired loan term *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="8"
						/>
					</Grid>
					{/* Desired interest type, commented for ticket sora2-564
					<Grid item xs={12} md={6}>
						
						<Dropdown
							items={['Fixed', 'Variable']}
							selected={desiredInterestType}
							onChange={setDesiredInterestType}
							variant="outlined"
							label="Desired interest type"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="9"
						/>
					</Grid>
					*/}
					<GetFullAddressPartialForm
						isRequired
						addressLine1={addressLine1}
						addressLine1TabIndex="10"
						addressLine2={addressLine2}
						addressLine2TabIndex="11"
						city={city}
						cityTabIndex="12"
						state={state}
						stateTabIndex="13"
						zipCode={zipCode}
						zipCodeTabIndex="14"
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					{/* <Grid item xs={6} marginBottom={4}>
						<TextInput
							inputProps={{
								'data-test': 'city',
								tabIndex: 10
							}}
							type={TEXT}
							label="City *"
							value={city}
							onChange={setCity}
						/>
					</Grid>
					<Grid item xs={6}>
						<Dropdown
							dataTestTag="state"
							variant="outlined"
							label="State *"
							items={US_STATE_OPTIONS}
							selected={state}
							onChange={setState}
							placeholder="State"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="11"
						/>
					</Grid>

					<Grid item xs={6}>
						<TextInput
							type={NUMBER}
							label="Zip code *"
							value={zipCode}
							onChange={setZipCode}
							inputProps={{
								onWheel: (e) => e.target.blur(),
								'data-test': 'zip',
								tabIndex: 12
							}}
							sx={{ marginBottom: 2 }}
						/>
					</Grid> */}

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="13"
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<RequiredFieldsTooltip>
					<span>
						<PrimaryButton
							disabled={!isFormReady}
							onClick={submitForm}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={14}
						>
							Submit
						</PrimaryButton>
					</span>
				</RequiredFieldsTooltip>
			</Grid>
		</Box>
	);
}

export default NewMortgageForm;
