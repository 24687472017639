import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import PageHeading from 'components/PageHeading/PageHeading';

// Our Components - Forms
import AutoRefiForm from 'components/Forms/NewLoanForms/auto/AutoRefiForm';
import CashOutRefi from 'components/Forms/NewLoanForms/otherLoanForms/CashOutRefiForm';
import ConstructionLoanInquiryForm from 'components/Forms/NewLoanForms/otherLoanForms/ConstructionLoanForm';
import FarmLoan from 'components/Forms/NewLoanForms/otherLoanForms/FarmLoanForm';
import HelocLoanForm from 'components/Forms/NewLoanForms/heloc/HelocForm';
import LotForm from 'components/Forms/NewLoanForms/otherLoanForms/LotLoanForm';
import MortgageRefinanceLoanForm from 'components/Forms/NewLoanForms/mortgage/MortgageRefinanceForm';
import NewAutoLoanForm from 'components/Forms/NewLoanForms/auto/NewAutoForm';
import NewPersonalLoan from 'components/Forms/NewLoanForms/personal/NewPersonalForm';
import NewMortgageLoanForm from 'components/Forms/NewLoanForms/mortgage/NewMortgageForm';
import PersonalRefinanceLoanForm from 'components/Forms/NewLoanForms/personal/PersonalRefinanceForm';
import ReverseMortgage from 'components/Forms/NewLoanForms/otherLoanForms/ReverseMortgageForm';
import SmallBusinessLoan from 'components/Forms/NewLoanForms/otherLoanForms/SmallBusinessForm';
import StudentLoanRefinanceLoanForm from 'components/Forms/NewLoanForms/student/StudentLoanRefinanceForm';
import Modal from 'components/Modal/Modal';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetClientsProfile from 'hooks/useGetClientsProfile';
import useGetProfile from 'hooks/useGetProfile';
import useGetWalletOverviewAsAdvisor from 'hooks/useGetWalletOverviewAsAdvisor';
import useQueryGetHubspotAccount from 'hooks/hubspot/useQueryGetHubspotAccount';

// Our utils
import { dataIsValid } from 'shared/utils';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

// Our Routes
import { INVITE_ROUTE, LOAN_INQUIRY_ROUTE } from 'routes/index';

// Our Constants
import { STUDENT, MORTGAGE, AUTO, PERSONAL, HELOC } from 'shared/constants';
import LOAN_INQUIRY_TYPES from 'shared/constants/LoanInquiry/LoanInquiryTypes';

// Local Constants
const NO_RELEVANT_LOANS = [
	'Construction Loan',
	'Farm Loan',
	'Lot Loan',
	'Commercial Loan'
];
const PRICE_A_LOAN_HEADING = 'Price a Client Loan';
const MAX_DROPDOWN_WIDTH = 350;

function PriceALoanPage() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// Deconstruct the URL to grab the client ID if present
	const currentURL = window.location.href;
	// Splits the currentURL by ? characters
	const urlPieces = currentURL.split('?');
	// Slices off the last item of the urlPieces Array, which should be the clientId. Looks like this currently [clientId]
	const idInArray = urlPieces.slice(-1);
	// Pulls the clientId out an array and makes clientId number
	const urlPassedClientId = idInArray[0];

	// Creating a boolean that will help us pre-populated the correct information in state
	// If the passedClientId includes loan-inquiry, there was no ID pass through via the URL
	const urlWithoutId = urlPassedClientId.includes(LOAN_INQUIRY_ROUTE);

	// Fetching this client's wallet overview data, where we'll grab the first and last name to be used
	// to pre-populate the client dropdown.
	const passedClientData = queryClient.getQueryData([
		WALLET_OVERVIEW_DATA,
		urlPassedClientId
	]);
	const passedFullName = `${passedClientData?.firstName} ${passedClientData?.lastName}`;

	// Client State
	const [selectedClient, setSelectedClient] = useState(
		urlWithoutId ? null : { id: urlPassedClientId, name: passedFullName }
	);
	const [clientSearchName, setClientSearchName] = useState(
		urlWithoutId ? '' : passedFullName
	);
	const [selectedLoanType, setSelectedLoanType] = useState('');
	const [selectedClientsLoans, setSelectedClientsLoans] = useState([]);
	const [selectedClientLoanLabel, setSelectedClientLoanLabel] = useState('');
	const [selectedClientLoan, setSelectedClientLoan] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	const {
		isLoading: isGetClientsLoading,
		isSuccess: isGetClientsSuccess,
		data
	} = useGetClients('');

	const { data: userProfileData } = useGetProfile();

	const {
		isLoading: isGetHubspotAccountLoading,
		isSuccess: isGetHubspotAccountSuccess
	} = useQueryGetHubspotAccount(userProfileData);

	// Call react Query to Retrieve Advisor name and email address from USERPROFILE
	const advisorFirstName = userProfileData?.firstName;
	const advisorLastName = userProfileData?.lastName;
	const advisorName = `${advisorFirstName} ${advisorLastName}`;
	const advisorEmail = userProfileData?.email;
	const advisorCompany = userProfileData?.companyName;
	const advisorWebsite = userProfileData?.companyWebsite;
	const isSomeClientSelected = selectedClient ?? false;

	// Trigger to fire client profile endpoint call
	const enabledGatherClientProfileCall =
		isGetClientsSuccess &&
		dataIsValid(data) &&
		!!isSomeClientSelected &&
		selectedClient.id !== 0;

	const { data: clientProfileData, isSuccess: clientProfileDataIsSuccess } =
		useGetClientsProfile(
			selectedClient?.id ?? 0,
			enabledGatherClientProfileCall,
			(profileData) => {
				const clientProfile = profileData;
				if (dataIsValid(clientProfile)) {
					return clientProfile;
				}
				return clientProfile;
			}
		);

	// Trigger to fire client wallet overview endpoint call
	const enabledGatherClientWalletOverviewCall =
		selectedClient?.id !== null &&
		selectedClient?.id !== 0 &&
		selectedClient?.id !== undefined &&
		clientProfileDataIsSuccess;

	const { data: clientWalletOverviewData } = useGetWalletOverviewAsAdvisor(
		selectedClient?.id ?? 0,
		enabledGatherClientWalletOverviewCall,
		(walletOverviewData) => {
			if (dataIsValid(walletOverviewData)) {
				return walletOverviewData;
			}
			return walletOverviewData;
		}
	);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfClients = useMemo(() => {
		if (isGetClientsSuccess) {
			const clientList = data.map(({ fullName, clientId }) => ({
				name: fullName,
				id: clientId
			}));

			// adding a new client options to client dropdown items
			clientList.unshift({ name: '+ New Client', id: 0 });

			return clientList;
		}

		return [];
	}, [data]);

	useEffect(() => {
		if (selectedClient?.id === 0) {
			// Open a modal that explains you must onboard a client before requesting a loan for them, direct advisors to client onboarding page
			setIsModalOpen(true);
		}
	}, [selectedClient]);

	const handleClientSelect = (
		currentSelectedClient,
		updateSelectedClient
	) => {
		updateSelectedClient(currentSelectedClient);
		setSelectedClient(currentSelectedClient);
		setSelectedLoanType('');
	};

	const handleLoanTypeChange = (value) => {
		setSelectedLoanType(value);
	};

	const handleSelectedClientLoan = (value) => {
		// eslint-disable-next-line array-callback-return
		selectedClientsLoans.map((singleLoan) => {
			if (singleLoan.lender === value) {
				setSelectedClientLoanLabel(singleLoan?.lender);
				setSelectedClientLoan(singleLoan);
			}
		});
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClient('');
		}
		setClientSearchName(incomingValue);
	};

	const clientAutoLiabilities =
		clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === AUTO
		);

	const clientHomeLiabilities =
		clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === MORTGAGE
		);

	const clientPersonalLiabilities =
		clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === PERSONAL
		);

	const clientStudentLiabilities =
		clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === STUDENT
		);

	useEffect(() => {
		if (
			dataIsValid(selectedLoanType) &&
			(selectedLoanType.includes('New') ||
				NO_RELEVANT_LOANS.includes(selectedLoanType))
		) {
			setSelectedClientsLoans([]);
		}

		if (selectedLoanType === 'Automotive Loan (Refinance)') {
			setSelectedClientsLoans(clientAutoLiabilities ?? []);
		}
		if (
			selectedLoanType === 'Mortgage Loan (Refinance)' ||
			selectedLoanType === HELOC ||
			selectedLoanType === 'Reverse Mortgage' ||
			selectedLoanType === 'Cash Out Refinance'
		) {
			setSelectedClientsLoans(clientHomeLiabilities ?? []);
		}
		if (selectedLoanType === 'Personal Loan (Refinance)') {
			setSelectedClientsLoans(clientPersonalLiabilities ?? []);
		}
		if (selectedLoanType === 'Student Loan (Refinance)') {
			setSelectedClientsLoans(clientStudentLiabilities ?? []);
		}

		setSelectedClientLoanLabel('');
	}, [selectedLoanType]);

	useEffect(() => {
		// Clean up
		setSelectedClientLoanLabel('');
		setSelectedClientLoan([]);
		setSelectedClientsLoans([]);
	}, [selectedClient]);

	const isLoading = isGetClientsLoading || isGetHubspotAccountLoading;
	const isSuccess = isGetClientsSuccess && isGetHubspotAccountSuccess;

	if (isLoading) {
		return (
			<>
				<PageHeading
					title={PRICE_A_LOAN_HEADING}
					subtitleText="Submitting a pricing request through Sora enables you to uncover loan offers and rates, without sharing any client personally identifiable information with lenders. Clients are never contacted without your express consent."
				/>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Skeleton
							variant="rectangular"
							height={500}
							sx={{ marginBottom: 4 }}
						/>
					</Grid>
				</Grid>
			</>
		);
	}

	if (isSuccess) {
		const hasClients = listOfClients.length > 0;
		return (
			<>
				<PageHeading
					title={PRICE_A_LOAN_HEADING}
					subtitleText={
						<Typography variant="body2" sx={{ marginBottom: 4 }}>
							Submitting a pricing request through Sora enables
							you to uncover loan offers and rates, without
							sharing any client personally identifiable
							information with lenders.
							<br />
							<br />
							Clients are never contacted without your express
							consent.
						</Typography>
					}
				/>

				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 4
					}}
				>
					<Typography variant="subtitle2" sx={{ marginRight: 6 }}>
						Select Client:
					</Typography>

					{hasClients && (
						<AutocompleteTextinput
							autoCompleteSX={{
								flexGrow: 2,
								maxWidth: MAX_DROPDOWN_WIDTH
							}}
							forcePopupIcon
							getOptionLabel={({ name }) => name}
							helperText=""
							handleChange={handleClientChange}
							handleSelect={handleClientSelect}
							label=""
							value={clientSearchName}
							optionList={listOfClients}
							withoutFilter={false}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							)}
						/>
					)}
					{!hasClients && (
						<Typography variant="body1">
							No clients were found. <br /> <br />
							Please add some or try again later.
						</Typography>
					)}
				</Grid>

				<Grid
					container
					item
					xs={12}
					sx={{ alignItems: 'center', marginBottom: 4 }}
				>
					<Typography
						variant="subtitle2"
						sx={{ display: 'block', marginRight: 8 }}
					>
						Loan Type:
					</Typography>
					{selectedClient?.id === 0 && (
						<Typography
							variant="body1"
							sx={{ marginTop: 2, maxWidth: MAX_DROPDOWN_WIDTH }}
						>
							You must onboard a new client before requesting a
							loan for them.
						</Typography>
					)}

					{selectedClient === '' && (
						<Typography variant="body1">
							Please select a client.
						</Typography>
					)}

					{isSomeClientSelected && selectedClient?.id !== 0 && (
						<Dropdown
							sx={{ flexGrow: 2, maxWidth: MAX_DROPDOWN_WIDTH }}
							items={LOAN_INQUIRY_TYPES}
							onChange={handleLoanTypeChange}
							selected={selectedLoanType}
						/>
					)}
				</Grid>

				<Grid container item xs={12} sx={{ alignItems: 'center' }}>
					<Typography variant="subtitle2" sx={{ marginRight: 4 }}>
						Relevant Loans:
					</Typography>
					{selectedLoanType === '' && (
						<Typography variant="body1">
							Please select a client and loan type.
						</Typography>
					)}

					{selectedLoanType && selectedClientsLoans?.length === 0 && (
						<Typography variant="body1">
							Client has no relevant loans
						</Typography>
					)}

					{selectedLoanType && selectedClientsLoans?.length !== 0 && (
						<Dropdown
							sx={{ flexGrow: 2, maxWidth: MAX_DROPDOWN_WIDTH }}
							items={
								selectedClientsLoans?.length === 0
									? ['Client has no relevant loans']
									: selectedClientsLoans.map(
											(singleLoan) => singleLoan.lender
									  )
							}
							onChange={handleSelectedClientLoan}
							selected={selectedClientLoanLabel}
						/>
					)}
				</Grid>

				{selectedLoanType === 'Mortgage Loan (New)' &&
					selectedClient && (
						<NewMortgageLoanForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							addressLine1Value={
								clientProfileData?.address?.addressLine1
							}
							addressLine2Value={
								clientProfileData?.address?.addressLine2
							}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							creditScoreValue={clientProfileData?.creditScore}
							emailAddressValue={clientProfileData?.email}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							veteranStatusValue={clientProfileData?.veteran}
							cityValue={clientWalletOverviewData?.address?.city}
							stateValue={
								clientWalletOverviewData?.address?.state
							}
							clientId={clientProfileData?.id}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Mortgage Loan (Refinance)' &&
					selectedClient && (
						<MortgageRefinanceLoanForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							addressLine1Value={
								clientProfileData?.address?.addressLine1
							}
							addressLine2Value={
								clientProfileData?.address?.addressLine2
							}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							clientId={clientProfileData?.id}
							creditScoreValue={clientProfileData?.creditScore}
							emailAddressValue={clientProfileData?.email}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							veteranStatusValue={clientProfileData?.veteran}
							estimatedValue={
								selectedClientLoan?.clientHome?.estimatedValue
							}
							primaryUseValue={
								selectedClientLoan?.clientHome?.propertyType
							}
							cityValue={selectedClientLoan?.clientHome?.city}
							stateValue={selectedClientLoan?.clientHome?.state}
							zipCodeValue={
								selectedClientLoan?.clientHome?.zipcode
							}
							currentMortgageBalance={
								selectedClientLoan?.outstandingBalance
							}
							currentMortgageRate={
								selectedClientLoan?.interestRate || '0'
							}
							refinancingTradelineId={
								selectedClientLoan?.tradelineId
							}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Automotive Loan (New)' &&
					selectedClient && (
						<NewAutoLoanForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							creditScore={clientProfileData?.creditScore}
							emailAddressValue={clientProfileData?.email}
							cityValue={clientWalletOverviewData?.address?.city}
							stateValue={
								clientWalletOverviewData?.address?.state
							}
							clientId={clientProfileData?.id}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Automotive Loan (Refinance)' &&
					selectedClient && (
						<AutoRefiForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							clientId={clientProfileData?.id}
							creditScore={clientProfileData?.creditScore}
							loanAmountValue={selectedClientLoan?.loanAmount}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							emailAddressValue={clientProfileData?.email}
							dateOfBirth={clientProfileData?.dob}
							stateValue={clientProfileData?.address?.state}
							vinValue={selectedClientLoan?.vehicleInfo?.vin}
							conditionValue={
								selectedClientLoan?.vehicleInfo?.condition
							}
							mileage={selectedClientLoan?.vehicleInfo?.mileage}
							refinancingTradelineId={
								selectedClientLoan?.tradelineId
							}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Student Loan (Refinance)' &&
					selectedClient && (
						<StudentLoanRefinanceLoanForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							assetsAmount={
								clientWalletOverviewData?.assetsAmount
							}
							clientId={clientProfileData?.id}
							creditScore={clientProfileData?.creditScore}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							employerValue={clientProfileData?.employer}
							employmentStatusValue={
								clientProfileData?.employmentStatus
							}
							educationLevelValue={
								clientProfileData?.highestDegree
							}
							schoolValue={clientProfileData?.school}
							graduationYearValue={
								clientProfileData?.graduationYear
							}
							dobValue={clientProfileData?.dob}
							addressLine1Value={
								clientProfileData?.address?.addressLine1
							}
							addressLine2={
								clientProfileData?.address?.addressLine2
							}
							cityValue={clientProfileData?.address?.city}
							stateValue={clientProfileData?.address?.state}
							zipCodeValue={clientProfileData?.address?.zipcode}
							emailAddressValue={clientProfileData?.email}
							lenderType={selectedClientLoan?.lenderType}
							outstandingBalance={
								selectedClientLoan?.outstandingBalance
							}
							refinancingTradelineId={
								selectedClientLoan?.tradelineId
							}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Personal Loan (New)' &&
					selectedClient && (
						<NewPersonalLoan
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							dobValue={clientProfileData?.dob}
							clientId={clientProfileData?.id}
							creditScoreValue={clientProfileData?.creditScore}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							assetsAmount={
								clientWalletOverviewData?.assetsAmount
							}
							employmentStatusValue={
								clientProfileData?.employmentStatus
							}
							employerValue={clientProfileData?.employer}
							veteranStatusValue={clientProfileData?.veteran}
							addressLine1Value={
								clientProfileData?.address?.addressLine1
							}
							addressLine2={
								clientProfileData?.address?.addressLine2
							}
							cityValue={clientProfileData?.address?.city}
							stateValue={clientProfileData?.address?.state}
							zipCodeValue={clientProfileData?.address?.zipcode}
							emailAddressValue={clientProfileData?.email}
							currentTermValue={selectedClientLoan?.currentTerm}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Personal Loan (Refinance)' &&
					selectedClient && (
						<PersonalRefinanceLoanForm
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							clientId={clientProfileData?.id}
							creditScoreValue={clientProfileData?.creditScore}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							veteranStatusValue={clientProfileData?.veteran}
							employerValue={clientProfileData?.employer}
							employmentStatusValue={
								clientProfileData?.employmentStatus
							}
							jobTitleValue={clientProfileData?.jobTitle}
							dobValue={clientProfileData?.dob}
							addressLine1Value={
								clientProfileData?.address?.addressLine1
							}
							addressLine2={
								clientProfileData?.address?.addressLine2
							}
							cityValue={clientProfileData?.address?.city}
							stateValue={clientProfileData?.address?.state}
							zipCodeValue={clientProfileData?.address?.zipcode}
							emailAddressValue={clientProfileData?.email}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							assetsAmount={
								clientWalletOverviewData?.assetsAmount
							}
							outstandingBalance={
								selectedClientLoan?.outstandingBalance
							}
							interestRate={selectedClientLoan?.interestRate}
							yearsRemaining={selectedClientLoan?.tenureMonth}
							currentTermValue={selectedClientLoan?.currentTerm}
							refinancingTradelineId={
								selectedClientLoan?.tradelineId
							}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === HELOC && selectedClient && (
					<HelocLoanForm
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileData?.id}
						creditScoreValue={clientProfileData?.creditScore}
						emailAddressValue={clientProfileData?.email}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						annualIncome={clientWalletOverviewData?.annualIncome}
						outstandingBalanceValue={
							selectedClientLoan?.outstandingBalance
						}
						estimatedValue={
							selectedClientLoan?.clientHome?.estimatedValue
						}
						primaryUseValue={
							selectedClientLoan?.clientHome?.propertyType
						}
						addressLine1Value={
							selectedClientLoan?.clientHome?.addressLine1
						}
						addressLine2Value={
							selectedClientLoan?.clientHome?.addressLine2
						}
						cityValue={selectedClientLoan?.clientHome?.city}
						stateValue={selectedClientLoan?.clientHome?.state}
						zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
						loanType={selectedLoanType}
					/>
				)}

				{selectedLoanType === 'Cash Out Refinance' &&
					selectedClient && (
						<CashOutRefi
							advisorName={advisorName}
							advisorEmail={advisorEmail}
							advisorCompany={advisorCompany}
							advisorWebsite={advisorWebsite}
							addressLine1Value={
								selectedClientLoan?.clientHome?.addressLine1
							}
							addressLine2Value={
								selectedClientLoan?.clientHome?.addressLine2
							}
							clientId={clientProfileData?.id}
							creditScore={clientProfileData?.creditScore}
							emailAddressValue={clientProfileData?.email}
							firstName={clientProfileData?.firstName || ''}
							lastName={clientProfileData?.lastName || ''}
							annualIncome={
								clientWalletOverviewData?.annualIncome
							}
							outstandingBalanceValue={
								selectedClientLoan?.outstandingBalance
							}
							estimatedValue={
								selectedClientLoan?.clientHome?.estimatedValue
							}
							primaryUseValue={
								selectedClientLoan?.clientHome?.propertyType
							}
							cityValue={selectedClientLoan?.clientHome?.city}
							stateValue={selectedClientLoan?.clientHome?.state}
							zipCodeValue={
								selectedClientLoan?.clientHome?.zipcode
							}
							refinancingTradelineId={
								selectedClientLoan?.tradelineId
							}
							loanType={selectedLoanType}
						/>
					)}

				{selectedLoanType === 'Reverse Mortgage' && selectedClient && (
					<ReverseMortgage
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileData?.id}
						creditScoreValue={clientProfileData?.creditScore}
						emailAddressValue={clientProfileData?.email}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						annualIncome={clientWalletOverviewData?.annualIncome}
						outstandingBalanceValue={
							selectedClientLoan?.outstandingBalance
						}
						estimatedValue={
							selectedClientLoan?.clientHome?.estimatedValue
						}
						primaryUseValue={
							selectedClientLoan?.clientHome?.propertyType
						}
						addressLine1Value={
							selectedClientLoan?.clientHome?.addressLine1
						}
						addressLine2Value={
							selectedClientLoan?.clientHome?.addressLine2
						}
						cityValue={selectedClientLoan?.clientHome?.city}
						stateValue={selectedClientLoan?.clientHome?.state}
						zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
						loanType={selectedLoanType}
					/>
				)}

				{selectedLoanType === 'Commercial Loan' && selectedClient && (
					<SmallBusinessLoan
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileData?.id}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						emailAddressValue={clientProfileData?.email}
						loanType={selectedLoanType}
					/>
				)}

				{selectedLoanType === 'Lot Loan' && selectedClient && (
					<LotForm
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						clientId={clientProfileData?.id}
						creditScoreValue={clientProfileData?.creditScore}
						emailAddressValue={clientProfileData?.email}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						annualIncome={clientWalletOverviewData?.annualIncome}
						stateValue={clientProfileData?.address?.state}
						loanType={selectedLoanType}
					/>
				)}

				{selectedLoanType === 'Farm Loan' && selectedClient && (
					<FarmLoan
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileData?.id}
						creditScoreValue={clientProfileData?.creditScore}
						emailAddressValue={clientProfileData?.email}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						loanType={selectedLoanType}
					/>
				)}

				{selectedLoanType === 'Construction Loan' && selectedClient && (
					<ConstructionLoanInquiryForm
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						annualIncome={clientWalletOverviewData?.annualIncome}
						clientId={clientProfileData?.id}
						creditScoreValue={clientProfileData?.creditScore}
						emailAddressValue={clientProfileData?.email}
						firstName={clientProfileData?.firstName || ''}
						lastName={clientProfileData?.lastName || ''}
						loanType={selectedLoanType}
					/>
				)}

				{/* New client selected modal */}
				<Modal
					title="Onboard A New Client"
					subtitle="You must onboard a new client before requesting a loan for them."
					isOpen={isModalOpen}
					handleClose={closeModal}
				>
					<Box>
						<PrimaryButton
							sx={{ marginTop: 4, marginRight: 4 }}
							onClick={() => {
								navigate(INVITE_ROUTE);
							}}
						>
							Onboard client
						</PrimaryButton>

						<SecondaryButton
							sx={{ marginTop: 4 }}
							onClick={closeModal}
						>
							Close
						</SecondaryButton>
					</Box>
				</Modal>
			</>
		);
	}
}

export default PriceALoanPage;
