import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import RateInput from 'components/Input/RateInput';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import SoraTextField from 'components/Input/TextArea';

// Form Input Types
import { NUMBER } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	MORTGAGE,
	MORTGAGE_LOAN_TENURE_OPTIONS,
	REFINANCE_OFFER_TYPE,
	TYPES_OF_HOME_PROPERTIES
} from 'shared/constants';

function MortgageRefinanceForm({
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	currentOutstandingBalance,
	currentInterestRateValue,
	desiredInterestTypeValue,
	emailAddressValue,
	estimatedValue,
	firstName,
	lastName,
	loanRequestId,
	postSuccessEvent,
	primaryUseValue,
	propertyTypeValue,
	remainingTermValue,
	stateValue,
	tenureValue,
	zipCodeValue,
	disableAutoComplete,
	setDisableAutoComplete,
	loanType
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [fullName, setFullName] = useState(fullNameValue || '');
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedValue || ''
	);
	const [loanAmount, setLoanAmount] = useState(
		currentOutstandingBalance || ''
	);
	const [currentInterestRate, setCurrentInterestRate] = useState(
		currentInterestRateValue || ''
	);
	const [tradelineId, setTradelineId] = useState('');
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [desiredInterestType, setDesiredInterestType] = useState(
		desiredInterestTypeValue || ''
	);

	const [remainingTerm, setRemainingTerm] = useState(
		remainingTermValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [ownershipTenure, setOwnershipTenure] = useState(tenureValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('Mortgage Loan Refinance Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		propertyType,
		primaryUse,
		loanAmount,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const loanRequestDetailsRequiredFields = [
		propertyType,
		primaryUse,
		loanAmount,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode,
		remainingTerm,
		totalAnnualIncome
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			),
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	useEffect(() => {
		if (primaryUseValue) {
			setPrimaryUse(primaryUseValue.toLowerCase());
		}
		if (estimatedValue) {
			setEstimatedPurchaseValue(estimatedValue);
		}
		if (cityValue) {
			setCity(cityValue);
		}
		if (stateValue) {
			setState(`${stateValue}`);
		}
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
		if (zipCodeValue) {
			setZipCode(zipCodeValue);
		}
		if (addressLine1Value) {
			setAddressLine1(addressLine1Value);
		}
		if (addressLine2Value) {
			setAddressLine2(addressLine2Value);
		}
	}, [
		cityValue,
		refinancingTradelineId,
		currentOutstandingBalance,
		currentInterestRate,
		estimatedValue,
		primaryUseValue,
		stateValue,
		zipCodeValue,
		addressLine1Value,
		addressLine2Value
	]);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has requested a Mortgage Loan Refinance for one of their clients. 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for a Mortgage Loan Refinance for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Address of home being refinanced: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
			Client's credit score: ${creditScore}
			Client's total annual income: ${totalAnnualIncome}
			Estimated purchase value of home being refinanced: ${estimatedPurchaseValue}
			Client's Current Mortgage Balance: ${loanAmount}
			Client's Current Mortgage Interest Rate: ${currentInterestRate}
			Primary use of property: ${primaryUse}
			Property type: ${propertyType}
			Client's expected ownership tenure: ${ownershipTenure}

			Client's remaining term of current loan ${remainingTerm}
			Client's desired interest type: ${desiredInterestType}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			additionalNotes,
			desiredInterestType,
			city,
			creditScore,
			loanAmount,
			estimatedPurchaseValue,
			remainingTerm,
			ownershipTenure,
			primaryUse,
			propertyType,
			state,
			totalAnnualIncome,
			zipCode,
			currentInterestRate,
			advisorName,
			advisorEmail,
			fullName,
			emailAddress,
			addressLine1,
			addressLine2
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setLoanAmount,
						setCity,
						setCreditScore,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setEstimatedPurchaseValue,
						setRemainingTerm,
						setDesiredInterestType,
						setFullName,
						setOwnershipTenure,
						setPrimaryUse,
						setPropertyType,
						setCurrentInterestRate,
						setState,
						setSubject,
						setTotalAnnualIncome,
						setZipCode
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			currentInterestRate: +currentInterestRate,
			desiredInterestType,
			estimatedPurchaseValue,
			firstName,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			remainingTerm,
			state,
			totalAnnualIncome,
			tradelineId,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			desiredInterestType,
			estimatedPurchaseValue,
			remainingTerm,
			firstName,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			currentInterestRate,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setLoanAmount,
						setCity,
						setCreditScore,
						setDesiredInterestType,
						setEmailAddress,
						setEstimatedPurchaseValue,
						setFullName,
						setRemainingTerm,
						setOwnershipTenure,
						setPrimaryUse,
						setPropertyType,
						setCurrentInterestRate,
						setState,
						setSubject,
						setTotalAnnualIncome,
						setZipCode
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},

			{
				type: 'percent',
				value: currentInterestRate,
				label: 'Current mortgage interest rate:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Current mortgage outstanding balance:'
			},
			{
				type: 'currency',
				value: estimatedPurchaseValue,
				label: 'Estimated Purchase Value:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Property Type: ${propertyType}
				Primary Use: ${primaryUse}
				Remaining loan term: ${remainingTerm}
				Desired loan term: ${ownershipTenure}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullName}`,
				dealtype: loanType,
				borrower: fullName,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Mortgage Loan Refinance
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Type of property input */}
						<Dropdown
							items={[
								'Single family home',
								'Apartment',
								'Townhouse',
								'Condominium',
								'Mobile home',
								'Multi-family home',
								'Bungalow',
								'Farmhouse'
							]}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Property type *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="1"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Primary use of property */}
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={primaryUse}
							onChange={setPrimaryUse}
							variant="outlined"
							label="Property use *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="2"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Mortgage Balance */}
						<CurrencyTextInput
							label="Current mortgage outstanding balance *"
							value={loanAmount}
							onChange={setLoanAmount}
							inputProps={{
								tabIndex: '3',
								'data-test': 'outstandingBalanceCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current mortgage interest rate *"
							inputProps={{
								tabIndex: '4',
								'data-test': 'interestRateCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Remaining term */}
						<Input
							type={NUMBER}
							label={
								atLoanRequestDetailsUrl
									? 'Remaining loan term *'
									: 'Remaining loan term'
							}
							subLabel="Length of loan in years"
							onChange={setRemainingTerm}
							value={remainingTerm}
							inputProps={{
								tabIndex: '5',
								'data-test': 'Desired loan term'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<Dropdown
							items={MORTGAGE_LOAN_TENURE_OPTIONS}
							selected={ownershipTenure}
							onChange={setOwnershipTenure}
							variant="outlined"
							label="Desired loan term *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="5"
						/>
					</Grid>
					{/* Desired interest type, commented for ticket sora2-564
					<Grid item xs={12} md={6}>
						
						<Dropdown
							items={['Fixed', 'Variable']}
							selected={desiredInterestType}
							onChange={setDesiredInterestType}
							variant="outlined"
							label="Desired interest type"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="6"
						/>
					</Grid>
					*/}
					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Annual income *'
									: 'Annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								tabIndex: '7',
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						isRequired
						addressLine1={addressLine1}
						addressLine1TabIndex="8"
						addressLine2={addressLine2}
						addressLine2TabIndex="9"
						city={city}
						cityTabIndex="10"
						state={state}
						stateTabIndex="11"
						zipCode={zipCode}
						zipCodeTabIndex="12"
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={11}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<RequiredFieldsTooltip>
					<span>
						<PrimaryButton
							disabled={!isFormReady}
							onClick={submitForm}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={12}
						>
							Submit
						</PrimaryButton>
					</span>
				</RequiredFieldsTooltip>
			</Grid>
		</Box>
	);
}

export default MortgageRefinanceForm;
